import React from "react"

import styled from "@emotion/styled"
const MyWrap = styled.div`
  margin: 0;
  padding: 0;
  margin-left: 6%;
  margin-right: 6%;
  width: 100%;
  background-color: white;
  /* min-height: 100vh; */
`
const _Wrap = props => {
  return <MyWrap>{props.children}</MyWrap>
}

_Wrap.propTypes = {}

export default _Wrap
