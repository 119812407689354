import React from "react"

import _Wrap from "~/components/_Wrap"
import styled from "@emotion/styled"
import { makeLink } from "../../utils/google-pic-format"
const LogoWrap = styled.div`
  max-width: 100%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @media all and (max-width: 1024px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media all and (max-width: 670px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media all and (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
  &&::after {
    content: "";
    flex: auto;
  }
`
const TheSingleLogo = styled.img`
  width: 90%;
  height: auto;
`
const SingleLogo = ({ src, alt }) => {
  return (
    <div style={{ marginBottom: "1rem" }}>
      <TheSingleLogo src={makeLink(src)} alt={alt}></TheSingleLogo>
    </div>
  )
}

const LogoHolder = ({ data }) => {
  return (
    <_Wrap>
      <LogoWrap>
        {data.edges.map(d =>
          d.node.partnerPicture ? (
            <SingleLogo
              src={d.node.partnerPicture}
              alt={d.node.partnerDescription}
            />
          ) : (
            ""
          )
        )}
      </LogoWrap>
    </_Wrap>
  )
}

LogoHolder.propTypes = {}

export default LogoHolder
