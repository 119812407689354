import React, { useEffect, useState } from "react"

import styled from "@emotion/styled"
import SingleImage from "./SingleImage"
import { SRLWrapper } from "simple-react-lightbox"
import { ignoreNA } from "../../utils/helpers"

const ImageGallery = styled.div`
  /* padding: 20px; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 1480px;
  margin: auto;
  justify-content: center;

  && img {
    height: ${props => props.imgWidth};
    max-width: ${props => props.imgWidth};
    transform: scale(1);
    transition: transform 0.4s ease;
  }

  .img-box {
    box-sizing: content-box;
    margin: 25px;
    /* flex-basis: 33%; */
    height: ${props => props.imgWidth};
    max-width: ${props => props.imgWidth};
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
  }

  .caption {
    position: absolute;
    bottom: 25px;
    left: 20px;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
    color: white;
    max-width: 300px;
  }
  .caption > p {
    font-size: 1.5rem;
  }

  .transparent-box {
    height: ${props => props.imgWidth};
    width: ${props => props.imgWidth};
    background-color: rgba(0, 0, 0, 0);
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 0.3s ease;
  }

  .img-box:hover img {
    transform: scale(1.1);
  }

  .img-box:hover .transparent-box {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .img-box:hover .caption {
    transform: translateY(-20px);
    opacity: 1;
  }

  .img-box:hover {
    cursor: pointer;
  }

  .caption > p:nth-child(2) {
    font-size: 1em;
  }

  .opacity-low {
    opacity: 0.5;
  }
  @media all and (max-width: 500px) {
    .img-box {
      height: auto;
      margin: 20px;
      margin-top: 0px;
    }
    && img {
      margin: 0;
      height: auto;
      width: 100%;
    }
  }
`

const SRLGalleryWrap = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  && > div {
    margin-left: auto;
    margin-right: auto;
  }
`
const Gallery = ({ data }) => {
  const [images, setImages] = useState([
    {
      title: "",
      description: "",
      src: "",
      index: 0,
    },
  ])

  useEffect(() => {
    if (data) {
      setImages(data)
    }
  }, [data])
  return (
    <SRLGalleryWrap>
      <SRLWrapper style={{ margin: "auto !important" }}>
        <ImageGallery imgWidth="430px">
          {images.map(image =>
            image.src ? (
              <SingleImage
                key={`${image.title}--${image.description}--${image.index}`}
                title={image.title}
                description={ignoreNA(image.description)}
                src={image.src}
                index={image.index}
              />
            ) : (
              ""
            )
          )}
        </ImageGallery>
      </SRLWrapper>
    </SRLGalleryWrap>
  )
}

Gallery.propTypes = {}

export default Gallery
