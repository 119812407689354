import React, { useEffect, useState } from "react"

import { Row, Container } from "react-bootstrap"
import GreyCTA from "../components/CTA/grey-cta"
import InnerWrap from "../components/_Wrap/inner-wrap"
import Hero, { FluidHero } from "../components/Hero"
import Gallery from "../components/Gallery"
import Paragraph from "../components/Paragraph"
import SEO from "../components/seo"
import LogoHolder from "../components/LogoHolder"
import CTA from "../components/CTA"
import { makeLink } from "../utils/google-pic-format"

const CorporateAwards = ({ data }) => {
  const { header, awards, partners } = data
  const [awardsPics, setAwardsPics] = useState([])
  useEffect(() => {
    let awardArray = []
    awards.edges.forEach(award => {
      awardArray.push({
        src: makeLink(award.node.corporateAwardsPicture),
        title: award.node.corporateAwardsPictureTitle,
        description: award.node.corporateAwardsPictureDescription,
        index: awards.edges.findIndex(
          c =>
            c.node.corporateAwardsPicture === award.node.corporateAwardsPicture
        ),
      })
    })
    setAwardsPics(awardArray)
  }, [awards.edges])
  return (
    <React.Fragment>
      <SEO
        title="Corporate Awards & Donor Gifts"
        keywords={[`brazee`, `cincinnati`, `art classes`, `corporate awards`]}
      />
      {/* <Hero src={`${makeLink(header.edges[0].node.headerPicture)}`}></Hero>s */}
      <FluidHero
        name={`${encodeURIComponent(
          header.edges[0].node.headerPictureTitle
        )}.png`}
      ></FluidHero>

      <Container fluid>
        <Row>
          <InnerWrap>
            <Paragraph
              title={header.edges[0].node.pagetitle}
              subheading={header.edges[0].node.subtitle}
            >
              <p
                dangerouslySetInnerHTML={{
                  __html: header.edges[0].node.paragraph.replace(
                    /\n/g,
                    "<br />"
                  ),
                }}
              ></p>
            </Paragraph>
          </InnerWrap>
        </Row>

        <Row>
          <Gallery data={awardsPics} />
        </Row>

        <Row>
          <CTA></CTA>
          <GreyCTA>
            <h4>Partners We've Worked With</h4>
          </GreyCTA>
        </Row>
        <Row>
          <LogoHolder data={partners} />
        </Row>
      </Container>
      <div style={{ height: "50px", backgroundColor: "white" }}></div>
    </React.Fragment>
  )
}

export const awardsQuery = graphql`
  query CorporateAwardsQuery {
    header: allGoogleSheetCorporateAwardsProductionRow {
      edges {
        node {
          pagetitle
          subtitle
          headerPicture
          headerPictureTitle
          paragraph
        }
      }
    }
    awards: allGoogleSheetCorporateAwardsProductionRow {
      edges {
        node {
          corporateAwardsPicture
          corporateAwardsPictureDescription
          corporateAwardsPictureTitle
        }
      }
    }
    partners: allGoogleSheetHomeRow {
      edges {
        node {
          partners
          partnerPicture
        }
      }
    }
  }
`

export default CorporateAwards
