import React from "react"

import styled from "@emotion/styled"

const CTAHolder = styled.div`
  background-color: var(--brz-grey);
  width: 100%;
  height: 20vh;
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  h4 {
    color: white;
  }
  @media all and (max-width: 768px) {
    h4 {
      font-size: 1.5rem;
    }
  }
`
const GreyCTA = props => {
  return <CTAHolder>{props.children}</CTAHolder>
}

export default GreyCTA
